import { template as template_8d6d831dfbea4fd58630988d5935ab3e } from "@ember/template-compiler";
const FKText = template_8d6d831dfbea4fd58630988d5935ab3e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
