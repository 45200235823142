import { template as template_2a7a3440ab75464c9f5346821ba3b1e4 } from "@ember/template-compiler";
const FKLabel = template_2a7a3440ab75464c9f5346821ba3b1e4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
