import { template as template_49764c8c84d94d7fb9954d06a8cb9151 } from "@ember/template-compiler";
const FKControlMenuContainer = template_49764c8c84d94d7fb9954d06a8cb9151(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
